import { AppShell, Box } from "@mantine/core";
import { useLocation } from "@remix-run/react";
import Header, { NavRoute } from "~/components/Header/Header";
import { centeredWithPadding } from "~/styles/centeredWithPadding";

type AppLayoutProps = {
  children: React.ReactNode;
  navRoutes?: NavRoute[];
  withPadding?: boolean;
};

const landingVariantRoutes = ["/", "/partnership"];

export const headerHeight = 60;

export default function AppLayout({
  children,
  navRoutes,
  withPadding = false,
}: AppLayoutProps) {
  const location = useLocation();
  const headerVariant = landingVariantRoutes.includes(location.pathname)
    ? "landing"
    : "default";

  return (
    <AppShell className="w-full relative" header={{ height: headerHeight }}>
      <AppShell.Header>
        <Header navRoutes={navRoutes ?? []} variant={headerVariant} />
      </AppShell.Header>
      <AppShell.Main className="bg-neutral-50 w-full flex flex-col">
        <Box pos="relative" style={{ minHeight: "100%", width: "100%" }}>
          {withPadding ? (
            <div className={centeredWithPadding}>{children}</div>
          ) : (
            children
          )}
        </Box>
      </AppShell.Main>
    </AppShell>
  );
}
