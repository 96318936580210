import { User } from "@supabase/supabase-js";

export function getSamlLogoutUrl(user: User | null) {
  if (
    user &&
    user.app_metadata.provider === "sso:70389b3e-8d9d-49ca-8156-0c74ccd1bb57" // schools.nyc.gov
  ) {
    return `https://idpcloud.nycenet.edu/idaas/mtfim/sps/idaas/logout?target=https://app.podsie.org/signin`;
  }
  return null;
}
