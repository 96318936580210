import { Button } from "@mantine/core";
import { Link, useOutletContext } from "@remix-run/react";
import { OutletContext } from "~/root";
import { getSamlLogoutUrl } from "./samlHelpers";

export default function AuthButton(props: { buttonText?: string }) {
  const { supabase, user } = useOutletContext<OutletContext>();
  const handleSignout = async () => {
    const {
      data: { user },
    } = await supabase.auth.getUser();

    await supabase.auth.signOut();

    const url = getSamlLogoutUrl(user);
    if (url) {
      window.location.href = url;
    } else {
      window.location.reload();
    }
  };
  if (user) {
    return (
      <Button className="h-8" onClick={handleSignout} color="primary">
        Log Out
      </Button>
    );
  }
  return (
    <Button className="h-8" component={Link} to="/signin" color="primary">
      {props.buttonText || "Sign In"}
    </Button>
  );
}
